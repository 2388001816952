import React, { useState, useEffect } from 'react'
import COLORS from '../resources/colors'
import Layout from '../components/layout'
import Logo from '../images/WebSiteSmallLogo-ClerkieColor.png'
import HeroImage from '../images/promark-banner.png'
import { navigate } from 'gatsby'
import { Location } from '@reach/router'
import queryString from 'query-string'
import heart from '../images/red-heart.png'
import whiteLogo from '../images/clerkie-logo-white.png'
import companyLogo from '../images/promark-white-logo.png'
import RoundProfileCells from '../components/RoundProfileCells'
import PercentAnimation from '../components/PercentAnimation'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import getPlayStoreUrl from '../getPlayStoreUrl'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import InfoCells from '../components/InfoCells'

const companyName = 'Pro-Mark'

const companyColor = '#00703C'

const companyID = 'promark'

const withLocation = ComponentToWrap => props => (
  <Location>
    {({ location, navigate }) => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
)

export default withLocation(SpaTheory)

function SpaTheory(props) {
  const heroHeight = 560
  const headerHeight = 75
  const [scrolled, _setScrolled] = useState(false)
  const scrolledRef = React.useRef(scrolled)
  const setScrolled = newValue => {
    _setScrolled(newValue)
    scrolledRef.current = newValue
  }

  const [language, setLanguage] = useState('English')

  var getClerkie = language == 'Spanish' ? 'Obtener Clerkie' : 'Get Clerkie'

  var heroTitle =
    language == 'Spanish'
      ? 'Ayudando a los empleados de Pro-Mark Aplastar Su Dueda'
      : 'Helping Pro-Mark Employees Crush Their Debt'
  var heroSubtitle =
    language == 'Spanish'
      ? 'y tomar el control de su dinero'
      : 'and take control of their money'
  var heroPromo =
    language == 'Spanish'
      ? 'Clerkie Premium Plus Gratis'
      : 'Clerkie Premium Plus for Free'

  var partnered =
    language == 'Spanish'
      ? 'Clerkie se ha asociado con'
      : 'Clerkie has partnered with'

  var builtForTitle = language == 'Spanish' ? 'Construido para' : 'Built for'
  var gridATitle =
    language == 'Spanish'
      ? 'Aumentar los ahorros + crear crédito'
      : 'Grow savings + build credit'
  var gridAParagraph =
    language == 'Spanish'
      ? 'Aprovechar nuestras herramientas de creación de crédito, Mejorar su puntaje de crédito mientras ahorra dinero o paga facturas'
      : 'Take advantage of our credit-building tools, improving your score while saving money or paying bills'

  var gridBTitle =
    language == 'Spanish'
      ? 'Negocie y pague su deuda'
      : 'Negotiate and payoff your debt'
  var gridBParagraph =
    language == 'Spanish'
      ? 'Los usarios de Clerkie ahorran $4,500+ dentro de sus primeros tres meses'
      : 'Clerkie users save $4,500+ within their first 3 months'

  var gridCTitle =
    language == 'Spanish'
      ? 'Mantenga más de su dinero duramente ganado'
      : 'Keep more of your hard-earned cash'
  var gridCParagraph =
    language == 'Spanish'
      ? '$42/mes ahorro promedio en seguro de auto, préstamos y contratos de arrendamiento'
      : '$42/month average savings on auto insurance, loans and leases'

  var gridDTitleA =
    language == 'Spanish' ? 'Oferta especial de Pro-Mark:' : 'Pro-Mark Special:'
  var gridDTitleB =
    language == 'Spanish' ? "'Premium Plus' gratis" : 'Premium Plus for Free'
  var gridDParagraph =
    language == 'Spanish'
      ? 'Obtenga acceso a funciones y recursos exclusivos para empleados de Pro-Mark sin coste adicional'
      : 'Access exclusive features + resources for Pro-Mark Employees at NO EXTRA COST'

  var dividerTitle =
    language == 'Spanish'
      ? 'Listo para ahorrar? Obtenga acceso gratuito a Premium Plus'
      : 'Ready to save? Get free access to Premium Plus'

  var infoCellsTitle =
    language == 'Spanish'
      ? 'Una solución simple y poderosa para todas las deudas'
      : 'A simple and powerful solution for all debt'

  var infoCellTitleA =
    language == 'Spanish' ? 'Asistente Financiero' : 'Financial Assistant'
  var infoCellTitleB =
    language == 'Spanish'
      ? 'Reducciones instantáneas de la deuda'
      : 'Instant Debt Reductions'
  var infoCellTitleC =
    language == 'Spanish' ? 'Construye tu crédito' : 'Build Your Credit'

  var infoCellParagraphA =
    language == 'Spanish'
      ? 'Cada miembro tiene acceso a un asistente financiero que entiende e inspira más allá de una aplicación independiente'
      : 'Every member gets access to a dedicated Financial Assistant who understands and inspires beyond a standalone app.'
  var infoCellParagraphB =
    language == 'Spanish'
      ? 'Nuestra plataforma automatizada brinda a los miembros acceso a ahorros instantáneos en la negociación de deudas para sus tarjetas de crédito y préstamos estudiantiles'
      : 'Our automated platform gives members access to instant debt negotiation savings for their credit cards and student loans.'
  var infoCellParagraphC =
    language == 'Spanish'
      ? 'Como miembros, tiene acceso a soluciones relevantes que lo ayudan a crear crédito y alcanzar sus objectivos a largo plazo'
      : 'As members, you get access to responsible solutions that help you build your credit and achieve long-term goals.'

  var reduceText =
    language == 'Spanish'
      ? 'Dicen que Clerkie ha ayudado a reducir o pagar las deudas'
      : 'say Clerkie has helped reduce or pay off debt'

  var debtPayoffTitle =
    language == 'Spanish'
      ? 'Pagar deudas nunca ha sido tan fácil'
      : 'Paying off debt has never been easier'
  var debtPayoffText =
    language == 'Spanish'
      ? 'Nuestros planes hacen que sea fácil para los miembros hacer los movimientos de dinero correctos y dar el primer paso hacia la salud financiera'
      : 'Our plans make it easy for members to make the right money moves and take the first step toward financial health'

  const [firstLoad, setFirstLoad] = useState(true)

  var params = (props && props.search) || {}

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      window.addEventListener('scroll', handleScroll)
      handleScroll()
    }
  }, [])

  function androidClicked() {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: 'Button',
      // string - required - Type of interaction (e.g. 'play')
      action: 'Click',
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: 'Android',
    })
    window.location.href = getPlayStoreUrl()
  }

  function appleClicked() {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: 'Button',
      // string - required - Type of interaction (e.g. 'play')
      action: 'Click',
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: 'iOS',
    })
    window.location.href =
      'https://itunes.apple.com/us/app/clerkie-money-advisor-finance/id1132917036?mt=8'
  }

  function handleScroll() {
    const scrollTop = window.pageYOffset
    if (scrollTop > heroHeight - headerHeight) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  function Hero() {
    return (
      <div className="PartnershipsHero">
        <div className="PartnershipsBackground">
          <div id="overlay"></div>
          <img src={HeroImage}></img>
        </div>

        <div className="PartnershipsContent PartnershipsContainer">
          <h1>{heroTitle}</h1>
          <h2 style={{ paddingTop: 20 }}>{heroSubtitle}</h2>
          <div className="Line"></div>
          <p>{heroPromo}</p>
          {/* <h3>(offer valid through May 2023)</h3> */}
          {getButton('70px', COLORS.white, COLORS.black)}
        </div>

        <style jsx="true">{`
          #overlay {
            display: block; //none; /* Hidden by default */
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(
              0,
              0,
              0,
              0.5
            ); /* Black background with opacity */
            z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
          }

          .PartnershipsHero {
            display: grid;
            padding-top: 0px;
            margin-top: 65px;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box; /* Firefox, other Gecko */
            box-sizing: border-box;
          }

          .PartnershipsBackground {
            grid-row: 1;
            grid-column: 1;
            overflow: hidden;
            position: relative;
            background: black;
            z-index: 0;
            margin-top: 0px;
            width: 100vw;
          }

          .PartnershipsBackground img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            opacity: 0.5;
          }

          .PartnershipsContent {
            justify-self: center;
            grid-row: 1;
            grid-column: 1;
            margin-top: 95px;
            z-index: 1;
            box-sizing: border-box;
            padding-bottom: 95px;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box; /* Firefox, other Gecko */
            box-sizing: border-box;
          }

          .PartnershipsContent h1 {
            text-align: center;
            font-weight: bold; //500;
            font-size: 40px; //35px;
            padding: 35px 0px 0px 0px;
            margin: 0px 50px;
          }

          .PartnershipsContent h2 {
            text-align: center;
            font-weight: 500;
            font-size: 30px;
            padding: 35px 0px 0px 0px;
            margin: auto;
          }

          .Line {
            width: 250px;
            margin-top: 20px;
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: auto;
            border: 0;
            border-radius: 3px;
            background: white;
            height: 6px;
            display: ;
          }

          .PartnershipsContent p {
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            padding: 0px 0px 0px 0px;
            margin: 10px auto;
          }

          .PartnershipsContent h3 {
            text-align: center;
            font-weight: 500;
            font-size: 10px;
            padding: 0px 0px 0px 0px;
            margin: 10px auto;
          }

          @media (max-width: 640px) {
            .PartnershipsHero {
              height: auto;
            }

            .PartnershipsContent h1 {
              margin: 0px 25px;
            }

            .PartnershipsContent h2 {
              margin: 10px 25px;
            }

            .PartnershipsContent p {
              margin: 10px 25px;
            }

            .PartnershipsContent h3 {
              margin: 10px 25px;
            }
          }
        `}</style>
      </div>
    )
  }

  function Partnered() {
    return (
      <div className="PartnershipPartnered">
        <div className="PartnershipPartneredContent PartnershipsContainer">
          <p>{partnered}</p>
          <img src={companyLogo}></img>
        </div>

        <style jsx="true">{`
          .PartnershipPartnered {
            height: auto;
            background: ${COLORS.myClerkieBlack};
          }

          .PartnershipPartneredContent {
            padding: 12.5px;
          }

          .PartnershipPartneredContent p {
            font-size: 14px;
            text-align: center;
            font-weight: 500;
            color: ${COLORS.white};
            padding: 0px;
            margin: 0px;
          }

          .PartnershipPartneredContent img {
            width: 150px;
            object-fit: fit;
            display: block;
            margin: 15px auto 0 auto;
            max-width: 150px;
          }
        `}</style>
      </div>
    )
  }

  function Designed() {
    return (
      <div className="PartnershipDesigned">
        <div className="PartnershipDesigned-Content PartnershipsContainer">
          <div className="PartnershipDesigned-TopContent">
            <img
              style={{ width: 50 }}
              src="https://s3.amazonaws.com/cdn.everlance.com/images/website/Teams+Page/Star+Icon.png"
            />
            <img
              style={{ width: 50 }}
              src="https://s3.amazonaws.com/cdn.everlance.com/images/website/Teams+Page/Star+Icon.png"
            />
            <img
              style={{ width: 50 }}
              src="https://s3.amazonaws.com/cdn.everlance.com/images/website/Teams+Page/Star+Icon.png"
            />
            <img
              style={{ width: 50 }}
              src="https://s3.amazonaws.com/cdn.everlance.com/images/website/Teams+Page/Star+Icon.png"
            />
            <img
              style={{ width: 50 }}
              src="https://s3.amazonaws.com/cdn.everlance.com/images/website/Teams+Page/Star+Icon.png"
            />
            <h2>
              {builtForTitle}{' '}
              <span style={{ color: companyColor, fontWeight: '800' }}>
                {companyName}
              </span>
            </h2>
          </div>

          <div className="PartnershipDesigned-BottomContent">
            <div className="PartnershipDesigned-Row">
              <div className="PartnershipDesigned-Feature">
                {/* money bag */}
                <img
                  src="https://clerkie-assets.s3-us-west-2.amazonaws.com/app-assets/save_split_goals/moneybag1_white_on_black.png"
                  alt="discount"
                />
                <div className="PartnershipDesigned-Feature-Content">
                  <h3>{gridATitle}</h3> {/* might add => (20% OFF Max)*/}
                  <p>{gridAParagraph}</p>
                  {/* <p>Avoid overdraft, late fees. Get up to $250 if you need it, with 20% OFF Max</p> */}
                  {/* <p>Avoid overdraft and late fees. Get access to up to $250 if you need it</p> */}
                </div>
              </div>
              <div className="PartnershipDesigned-Feature">
                {/* discount */}
                <img
                  src="https://clerkie-assets.s3-us-west-2.amazonaws.com/app-assets/save_split_goals/price_white_on_black.png"
                  alt="discount"
                />
                <div className="PartnershipDesigned-Feature-Content">
                  <h3>{gridBTitle}</h3>
                  <p>{gridBParagraph}</p>
                </div>
              </div>
            </div>
            <div className="PartnershipDesigned-Row">
              <div className="PartnershipDesigned-Feature">
                {/* chart OR car */}
                <img
                  src="https://clerkie-assets.s3-us-west-2.amazonaws.com/app-assets/save_split_goals/car_white_on_black.png"
                  alt="discount"
                />
                <div className="PartnershipDesigned-Feature-Content">
                  <h3>{gridCTitle}</h3>
                  <p>{gridCParagraph}</p>
                </div>
              </div>
              <div className="PartnershipDesigned-Feature">
                {/* discount OR Trophy Or rocket */}
                <img
                  src="https://clerkie-assets.s3-us-west-2.amazonaws.com/app-assets/save_split_goals/rocket_white_on_black.png"
                  alt="discount"
                />
                <div className="PartnershipDesigned-Feature-Content">
                  <h3>
                    {gridDTitleA}{' '}
                    <span style={{ color: companyColor }}>{gridDTitleB}</span>
                  </h3>
                  <p>{gridDParagraph}</p>
                </div>
              </div>
            </div>
          </div>

          {/* {getButton("0px")} */}
        </div>

        <style jsx="true">{`
          .PartnershipDesigned {
            height: auto;
          }

          .PartnershipDesigned-Content {
            display: grid;
            padding-top: 100px;
            margin: auto;
            padding-bottom: 125px;
          }

          .PartnershipDesigned-TopContent {
            text-align: center;
            margin-bottom: 35px;
          }

          .PartnershipDesigned-TopContent h2 {
            color: white;
            font-weight: 500;
            font-size: 34px;
            padding: 0px;
            margin: 0px 0px 7px 0px;
            line-height: 40px;
          }

          .PartnershipDesigned-BottomContent {
            margin: auto;
          }

          .PartnershipDesigned-Row {
            display: grid;
            margin-bottom: 0px;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            width: 100%;
          }

          @media (min-width: 992px) {
            .PartnershipDesigned-Row {
              grid-template-columns: 1fr 1fr;
              grid-template-rows: 1fr;
              margin-bottom: 20px;
              width: 990px;
            }
          }

          .PartnershipDesigned-Feature {
            display: grid;
            grid-template-columns: 1fr;
            gird-template-rows: 1fr 1fr;
            grid-gap: 0px;
            text-align: center;
            margin-bottom: 25px;
          }

          .PartnershipDesigned-Feature img {
            height: 40px;
            width: 40px;
            position: relative;
            top: 10px;
            margin: auto;
          }

          .PartnershipDesigned-Feature-Content {
            width: 100%;
          }

          .PartnershipDesigned-Feature-Content h3 {
            color: white;
            font-weight: bolder; //500;
            font-size: 16px;
            padding: 0px;
          }

          .PartnershipDesigned-Feature-Content p {
            font-size: 19px;
            line-height: 24px;
            font-weight: lightest; //300;
            color: #adb9ca; //#797979;
            padding-left: 5px;
          }

          @media (min-width: 992px) {
            .PartnershipDesigned-Feature {
              display: grid;
              grid-template-columns: 40px 1fr;
              grid-gap: 20px;
              text-align: left;
              margin-bottom: 0px;
            }

            .PartnershipDesigned-Feature img {
              margin: 0px;
            }

            .PartnershipDesigned-Feature-Content {
              width: 80%;
            }

            .PartnershipDesigned-Feature-Content p {
              padding-left: 0px;
            }
          }
        `}</style>
      </div>
    )
  }

  function NumberOne() {
    return (
      <div className="PartnershipNumberOne">
        <div className="PartnershipNumberOneContent PartnershipsContainer Partnership-Row">
          <div className="PartnershipNumberOne-Header">
            <h2>What is Clerkie?</h2>
          </div>
          <div className="PartnershipNumberOne-BottomContent">
            <div className="PartnershipNumberOne-BottomContent-Top">
              <h4>The #1 Mileage &amp; Expense Tracker</h4>
              <p>
                100% automatic mileage tracking. IRS compliant. Delightful
                interface.
              </p>
              <h4>Bank and Credit Card Sync </h4>
              <p>
                Record all your business expenses automatically. Lose the
                shoebox of receipts.
              </p>
            </div>
            <div className="PartnershipNumberOne-BottomContent-Bottom">
              <img
                src="https://www.everlance.com/images/layered-iphone.png"
                alt="expense tracking"
              />
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .PartnershipNumberOne {
            height: auto;
            width: 100%;
            background: #31b59f;
          }

          .PartnershipNumberOneContent {
            display: grid;
            padding-top: 100px;
            margin: auto;
            padding-bottom: 125px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
          }

          .PartnershipNumberOne-Header h2 {
            color: transparent;
            font-weight: 700;
            font-size: 35px;
            text-align: center;
            display: none;
          }

          @media (min-width: 992px) {
            .PartnershipNumberOne-Header h2 {
              color: white;
              display: block;
            }
          }

          .PartnershipNumberOne-BottomContent {
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
            justify-self: center;
            grid-gap: 0px;
            align-items: start;
          }

          @media (min-width: 992px) {
            .PartnershipNumberOne-BottomContent {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-template-rows: 1fr;
              width: 960px;
              grid-gap: 20px;
              margin-top: 60px;
            }
          }

          @media (min-width: 992px) {
            .PartnershipNumberOne-BottomContent h4 {
              text-align: left;
            }
          }

          .PartnershipNumberOne-BottomContent h4 {
            color: white;
            margin-top: 35px;
            font-weight: 400;
            font-size: 31px;
            line-height: 1.2;
            padding: 0px;
            margin: 0px 0px 0px 0px;
            text-align: center;
          }

          @media (min-width: 992px) {
            .PartnershipNumberOne-BottomContent h4 {
              text-align: left;
            }
          }

          .PartnershipNumberOne-BottomContent p {
            color: white;
            font-size: 19px;
            line-height: 24px;
            width: 100%;
            font-weight: 300;
            padding: 0px;
            margin: 20px 0px 35px 0px;
            text-align: center;
          }

          @media (min-width: 992px) {
            .PartnershipNumberOne-BottomContent p {
              text-align: left;
              width: 83%;
            }
          }

          .PartnershipNumberOne-BottomContent img {
            margin: 0 auto !important;
            margin-top: -330px;
            max-height: 520px;
            display: block;
            max-width: 100%;
            height: auto;
          }

          .PartnershipNumberOne-BottomContent-Top {
            grid-row: 1;
            grid-column: 1;
            width: 100%;
          }

          @media (min-width: 992px) {
            .PartnershipNumberOne-BottomContent-Top {
              grid-row: 1;
              grid-column: 2;
              width: 100%;
            }
          }

          .PartnershipNumberOne-BottomContent-Bottom {
            grid-row: 2;
            grid-column: 1;
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
          }

          @media (min-width: 992px) {
            .PartnershipNumberOne-BottomContent-Bottom {
              grid-row: 1;
              grid-column: 1;
            }
          }
        `}</style>
      </div>
    )
  }

  function getButton(
    topMargin,
    backgroundColor = COLORS.clerkieColor,
    color = COLORS.white
  ) {
    return (
      <div className="PartnershipCTA">
        <button onClick={event => getClerkieTapped(event)}>{getClerkie}</button>
        <style jsx="true">{`
          .PartnershipCTA {
            width: auto;
            display: block;
            display: block;
            margin: ${topMargin} auto 0px auto;
            width: fit-content;
            height: fit-content;
          }

          .PartnershipCTA button {
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            zoom: 1;
            padding: 20px 40px;
            font-weight: 700;
            font-size: 16px;
            color: ${color} !important;
            border: 0px;
            border-radius: 100px;
            background-color: ${backgroundColor};
            -webkit-transition: all 0.2s linear;
            -moz-transition: all 0.2s linear;
            -ms-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;
            transition: all 0.2s linear;
          }
        `}</style>
      </div>
    )
  }

  function TopRated() {
    return (
      <div className="PartnershipTopRated">
        <div className="PartnershipTopRated-Content PartnershipsContainer Partnership-Row">
          <div className="PartnershipTopRated-Content-Header">
            <h2>
              Top Rated Mileage Tracker &amp; Trusted by +1 Million People{' '}
            </h2>
          </div>
          <div className="PartnershipTopRated-Content-Content">
            <div className="PartnershipTopRated-Content-Content-Card">
              <div>
                <img src="https://s3.amazonaws.com/cdn.everlance.com/images/website/Partner-Education/taxacademylogo.png"></img>
                <h5>
                  "Best New Apps"
                  <br />
                  by Apple &amp; Google
                </h5>
              </div>
            </div>
            <div className="PartnershipTopRated-Content-Content-Card">
              <div>
                <img src="https://s3.amazonaws.com/cdn.everlance.com/images/website/Partner-Education/taxacademylogo.png"></img>
                <h5>
                  #1 Rated Mileage Tracking
                  <br />
                  App & Expense Log
                </h5>
              </div>
            </div>
            <div className="PartnershipTopRated-Content-Content-Card">
              <div>
                <img src="https://s3.amazonaws.com/cdn.everlance.com/images/website/Partner-Education/taxacademylogo.png"></img>
                <h5>
                  <br></br>Rated 4.9/5 Stars
                </h5>
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .PartnershipTopRated {
            height: auto;
            text-align: center;
          }

          .PartnershipTopRated-Content {
            background: #fafafa;
            margin-top: 80px;
            border-top: 0.3px solid #fff;
            border-bottom: 0.3px solid #fff;
            padding-top: 40px;
            padding-bottom: 60px;
          }

          .PartnershipTopRated-Content-Header {
          }

          .PartnershipTopRated-Content-Header h2 {
            font-size: 25px;
            color: #555c68;
            line-height: 32px;
            font-weight: 700;
            margin: 5% 5% 5% 5%;
            text-align: center;
            padding: 0px;
          }

          .PartnershipTopRated-Content-Content {
            margin-right: -15px;
            margin-left: -15px;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 40px;
          }

          @media (min-width: 992px) {
            .PartnershipTopRated-Content-Content {
              grid-template-columns: 1fr 1fr 1fr;
              grid-gap: 0px;
            }
          }

          .PartnershipTopRated-Content-Content-Card {
            width: 80%;
            jusitfy-self: center;
            margin: auto;
            color: #4c4c4c;
            padding: 16px 25px;
            border-radius: 5px;
            position: relative;
            box-shadow: 0px 17px 45px 2px rgba(0, 0, 0, 0.18);
            overflow: hidden;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
          }

          .PartnershipTopRated-Content-Content-Card img {
            object-fit: fit;
            max-width: 95px;
            padding: 0px;
            margin: 0px 0px 10px 0px;
          }

          .PartnershipTopRated-Content-Content-Card h5 {
            font-size: 14px;
            font-weight: 400;
            padding: 0px;
            margin: 0px;
          }
        `}</style>
      </div>
    )
  }

  function getClerkieTapped() {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: 'Button',
      // string - required - Type of interaction (e.g. 'play')
      action: 'Click',
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: 'Signup',
    })

    let languageKey = language == 'Spanish' ? 'es' : 'en'
    var url = `https://app.clerkie.io/signup?source=${companyID}&language=${languageKey}`
    var count = 0

    for (const key in params) {
      let value = params[key]
      url += `&${key}=${value}`
      count += 1
    }

    window.location.href = url
  }

  function loginTapped() {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: 'Button',
      // string - required - Type of interaction (e.g. 'play')
      action: 'Click',
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: 'Login',
    })

    let languageKey = language == 'Spanish' ? 'es' : 'en'
    var url = `https://app.clerkie.io/?source=${companyID}&language=${languageKey}`

    var count = 0

    for (const key in params) {
      let value = params[key]
      url += `&${key}=${value}`
      count += 1
    }

    window.location.href = url
  }

  function Ready() {
    return (
      <div className="PartnershipReady">
        <div className="PartnershipReadyContent PartnershipsContainer Partnership-Row">
          <div className="PartnershipReadyInnerContent">
            <div>
              <p>{dividerTitle} 💪</p>
            </div>
            <div>
              <button
                className="PartnershipReady-Button"
                onClick={event => getClerkieTapped()}
              >
                {getClerkie}
              </button>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .PartnershipReady {
            height: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 60px;
            margin-bottom: 60px;
          }

          .PartnershipReadyContent {
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
            overflow-x: visible;
          }

          .PartnershipReadyInnerContent {
            box-shadow: 0px 17px 45px 2px rgba(0, 0, 0, 0.18);
            overflow: hidden;
            text-align: center;
            padding: 40px;
            border-radius: 20px;
            background-color: ${COLORS.myClerkieBlack};
            display: grid;
            align-items: center;
          }

          @media (min-width: 992px) {
            .PartnershipReadyInnerContent {
              grid-template-columns: 75% 25%;
              padding: 40px;
            }
          }

          .PartnershipReady p {
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            color: #fff;
            padding: 0px 0px 20px 0px;
            margin: 0px;
          }

          @media (min-width: 992px) {
            .PartnershipReady p {
              padding: 0px 0px 0px 0px;
              margin: 0px;
            }
          }

          .PartnershipReady button {
            display: inline-block;
            margin-bottom: 0;
            font-weight: normal;
            line-height: 1.42857143;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-image: none;
            color: ${COLORS.white};
            background: ${COLORS.black};
            border: 0px solid transparent;
            border-radius: 40px;
            cursor: pointer;
            transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
            padding: 12px 24px;
            font-size: 13px;
            font-weight: 600;
            margin-left: 10px;
          }
        `}</style>
      </div>
    )
  }

  function Academy() {
    return (
      <div className="PartnershipAcademy">
        <div className="PartnershipAcademyContent PartnershipsContainer Partnership-Row">
          <div className="PartnershipAcademyContent-Header">
            <img
              style={{ maxWidth: 300 }}
              src="https://s3.amazonaws.com/cdn.everlance.com/images/website/Partner-Education/taxacademylogo.png"
            />
            <p>Learn to show taxes who's boss.</p>
          </div>
          <div className="PartnershipAcademyContent-Cards">
            <div className="PartnershipAcademyContent-Cards-Content">
              <div className="PartnershipAcademyContent-Cards-Content-Card">
                <h3>
                  Maximizing Your<br></br>Mileage Write-Off
                </h3>
                <img src="https://www.pngonly.com/wp-content/uploads/2017/06/transparent-car-png-10207.png"></img>
              </div>
              <div className="PartnershipAcademyContent-Cards-Content-Card">
                <h3>
                  Maximizing Your<br></br>Mileage Write-Off
                </h3>
                <img src="https://www.pngonly.com/wp-content/uploads/2017/06/transparent-car-png-10207.png"></img>
              </div>
              <div className="PartnershipAcademyContent-Cards-Content-Card">
                <h3>
                  Maximizing Your<br></br>Mileage Write-Off
                </h3>
                <img src="https://www.pngonly.com/wp-content/uploads/2017/06/transparent-car-png-10207.png"></img>
              </div>
              <div className="PartnershipAcademyContent-Cards-Content-Card">
                <h3>
                  Maximizing Your<br></br>Mileage Write-Off
                </h3>
                <img src="https://www.pngonly.com/wp-content/uploads/2017/06/transparent-car-png-10207.png"></img>
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .PartnershipAcademy {
            height: auto;
            background: white;
          }

          .PartnershipAcademyContent {
            margin: 60px auto 0px auto;
          }

          .PartnershipAcademyContent img {
          }

          .PartnershipAcademyContent-Header {
            text-align: center;
          }

          .PartnershipAcademyContent-Header img {
            margin: auto;
            display: block;
          }

          .PartnershipAcademyContent-Header p {
            font-size: 20px;
            font-weight: 300;
            color: #888;
            margin: 0px;
          }

          .PartnershipAcademyContent-Cards {
            margin-top: 65px;
          }

          .PartnershipAcademyContent-Cards-Content {
            display: grid;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;

            grid-template-columns: 1fr;
          }

          @media (min-width: 992px) {
            .PartnershipAcademyContent-Cards-Content {
              grid-template-columns: 1fr 1fr;
            }
          }

          .PartnershipAcademyContent-Cards-Content-Card {
            box-shadow: 0px 17px 45px 2px rgba(23, 43, 99, 0.3);
            border-radius: 10px;
            max-width: 85%;
            width: 85%;
            display: block;
            margin: auto;
            margin-top: 20px;
          }

          .PartnershipAcademyContent-Cards-Content-Card img {
            height: 150px;
            display: block;
            margin: auto;
            margin-bottom: 40px;
          }

          .PartnershipAcademyContent-Cards-Content-Card h3 {
            color: black;
            text-align: center;
            max-width: 90%;
            line-height: 30px;
            font-size: 24px;
            margin: auto;
            margin-top: 40px;
            margin-bottom: 10px;
          }
        `}</style>
      </div>
    )
  }

  function Footer() {
    return (
      <div className="PartnershipFooter">
        <div className="PartnershipFooterContent PartnershipsContainer Partnership-Row">
          <div className="PartnershipFooterContent-Store-Buttons">
            <div className="PartnershipFooter-Apple">
              <a onClick={event => appleClicked()}>
                <img src={require('../images/app_store.svg')}></img>
              </a>
            </div>
            <div className="PartnershipFooter-Android">
              <a onClick={event => androidClicked()}>
                <img src={require('../images/play_store.png')}></img>
              </a>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .PartnershipFooter {
            height: auto;
            test-align: center;
            background: ${COLORS.myClerkieBlack};
          }

          .PartnershipFooterContent {
            margin-top: 120px;
            padding-top: 60px;
            padding-bottom: 60px;
          }

          .PartnershipFooterContent p {
            padding-top: 20px;
            font-size: 13px;
            text-align: center;
            color: #a3aab5;
            font-weight: 400;
          }

          .PartnershipFooterContent-Store-Buttons {
            display: grid;
            grid-template-rows: 1fr;
            align-items: center;
          }

          .PartnershipFooter-Android {
            margin: auto;
          }

          .PartnershipFooter-Android img {
            width: 122px;
            object-fit: fit;
          }

          .PartnershipFooter-Apple {
            margin: auto;
            margin-bottom: 15px;
          }

          .PartnershipFooter-Apple img {
            width: 98px;
            object-fit: fit;
          }
        `}</style>
      </div>
    )
  }

  function getHeaderButton(
    title,
    backgroundColor,
    color,
    action,
    mobileHide = false
  ) {
    return (
      <div className="PartnershipsHeader-Button">
        <button key={title} onClick={action}>
          {title}
        </button>
        <style jsx>{`
          .PartnershipsHeader-Button button {
            color: ${color};
            background: ${backgroundColor};
            border: 0px solid transparent;
            border-radius: 40px;
            cursor: pointer;
            transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
            padding: 0px 25px;
            height: 40px;
            font-size: 13px;
            font-weight: bold;
            margin-left: 10px;
          }

          .PartnershipsHeader-Button button:focus {
            outline: none;
          }

          .PartnershipsHeader-Button button:active:focus {
            outline: none;
          }

          @media (max-width: 640px) {
            .PartnershipsHeader-Button {
              display: ${mobileHide ? 'none' : 'block'};
            }

            .PartnershipsHeader-Button button {
              font-size: 2.5vw;
              padding: 0px 4.9vw;
              height: 7.93vw;
            }
          }
        `}</style>
      </div>
    )
  }

  function getHeader() {
    let buttonColor = 'black'
    let buttonBackgroundColor = 'white'

    let clerkieUrl = whiteLogo

    return (
      <div className="PartnershipsHeader">
        <div className="PartnershipHeaderContent">
          <div className="PartnershipsHeaderLeft">
            <div className="PartnershipsHeaderLeft-ClerkieLogo">
              <img src={clerkieUrl}></img>
            </div>
            <div className="PartnershipsHeaderLeft-Heart">
              <img src={heart}></img>
            </div>
            <div className="PartnershipsHeaderLeft-PartnerLogo">
              <img src={companyLogo}></img>
            </div>
          </div>

          <div className="PartnshipsHeaderButtons">
            {getHeaderButton(
              getClerkie,
              buttonBackgroundColor,
              buttonColor,
              getClerkieTapped,
              true
            )}
            {getHeaderButton(
              'Login',
              buttonBackgroundColor,
              buttonColor,
              loginTapped
            )}
          </div>
        </div>

        <style jsx="true">{`
          .PartnershipsHeader {
            position: fixed;
            top: 35px;
            width: 100%;
            height: ${headerHeight}px;
            background: ${scrolled == true ? 'black' : 'black'};
            z-index: 100;
            transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
          }

          .PartnershipHeaderContent {
            display: grid;
            grid-template-columns: 15px 1fr 1fr 15px;
            grid-template-rows: 1;
            align-items: center;
            height: ${headerHeight}px;
          }

          .PartnershipsHeaderLeft {
            display: flex;
            grid-column: 2;
            grid-row: 1;
            justify-content: center;
            align-items: center;
            margin-left: 15px;
            justify-content: flex-start;
          }

          .PartnshipsHeaderButtons {
            grid-column: 3;
            grid-row: 1;
            display: flex;
            justify-content: flex-end;
          }

          .PartnershipsHeaderLeft-PartnerLogo {
            display: block;
          }

          .PartnershipsHeaderLeft-ClerkieLogo {
            margin-right: 15px;
            width: 35px;
            height: 35px;
          }

          .PartnershipsHeaderLeft-Heart {
            display: block;
            margin-right: 15px;
          }

          .PartnershipsHeaderLeft-ClerkieLogo img {
            width: 35px;
            height: 35px;
            object-fit: fit;
            display: block;
          }

          .PartnershipsHeaderLeft-Heart img {
            //desktop
            width: 20px;
            height: 20px;
            object-fit: fit;
            display: block;
          }

          .PartnershipsHeaderLeft-PartnerLogo img {
            width: 150px;
            object-fit: fit;
            display: block;
            max-width: 110px;
          }

          @media (max-width: 640px) {
            .PartnershipsHeaderLeft-ClerkieLogo {
              width: 6vw;
              height: 6vw;
              margin-right: 2.5vw;
            }

            .PartnershipsHeaderLeft-Heart {
              margin-right: 2.5vw;
            }

            .PartnershipsHeaderLeft-ClerkieLogo img {
              width: 6vw;
              height: 6vw;
              object-fit: fit;
              display: block;
            }

            .PartnershipsHeaderLeft-Heart img {
              //desktop
              width: 4vw;
              height: 4vw;
              object-fit: fit;
              display: block;
            }

            .PartnershipsHeaderLeft-PartnerLogo img {
              width: 30vw;
            }
          }
        `}</style>
      </div>
    )
  }

  return (
    <ParallaxProvider>
      <Layout
        hideHeader={true}
        primaryLogo={Logo}
        primaryColor={COLORS.black}
        alwaysPrimary={false}
        heroMobileMultiplier={0}
        heroDesktopMultiplier={0}
        buttons={[
          {
            title: 'Login',
            action: loginTapped,
          },
          {
            title: getClerkie,
            action: getClerkieTapped,
            showOnMobile: true,
            isSecondary: true,
          },
        ]}
        menuButtons={[
          {
            title: 'Lenders',
            type: 'item',
            action: function() {
              navigate('/')
            },
          },
          {
            title: 'Companies',
            type: 'item',
            action: function() {
              navigate('/companies')
            },
          },
          {
            title: 'Members',
            type: 'item',
            action: function() {
              navigate('/members')
            },
          },
        ]}
      >
        <div className="PartnershipsRoot">
          <div className="TopBanner">
            <div className="selectContainer">
              <p className="selectTitle">Select Language:</p>
              <select
                className="select"
                style={{ color: 'rgb(99, 108, 107)' }}
                value={language}
                onChange={event => setLanguage(event.target.value)}
              >
                {/* <option value="" selected="selected">Language</option> */}
                <option value="English">English</option>
                <option value="Spanish">Español</option>
              </select>
            </div>
          </div>
          {getHeader()}
          {Hero()}
          {Partnered()}
          {Designed()}
          {Ready()}
          <InfoCells
            componentID={companyID}
            title={infoCellsTitle}
            titleA={infoCellTitleA}
            paragraphA={infoCellParagraphA}
            titleB={infoCellTitleB}
            paragraphB={infoCellParagraphB}
            titleC={infoCellTitleC}
            paragraphC={infoCellParagraphC}
          />
          <PercentAnimation componentID={companyID} text={reduceText} />
          <RoundProfileCells
            componentID={companyID}
            displayForm={getClerkieTapped}
            title={debtPayoffTitle}
            text={debtPayoffText}
            buttonText={getClerkie}
          />
          {Ready()}
          {Footer()}

          <style global jsx="true">{`
            html,
            body {
              font-family: 'Helvetica Neue', 'Arial';
              margin: 0;
              padding: 0;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              max-width: 100%;
              background-color: black;
            }

            * {
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
            }

            .PartnershipsContainer {
              padding-right: 15px;
              padding-left: 15px;
              margin-right: auto;
              margin-left: auto;
            }

            @media (min-width: 768px) {
              .PartnershipsContainer {
                width: 750px;
              }
            }

            @media (min-width: 992px) {
              .PartnershipsContainer {
                width: 970px;
              }
            }

            @media (min-width: 1200px) {
              .PartnershipsContainer {
                width: 1170px;
              }
            }

            .Partnership-Row {
            }

            .TopBanner {
              height: 35px;
              position: fixed;
              background-color: ${COLORS.myClerkieBlack};
              width: 100%;
              top: 0;
              left: 0;
              right: 0;
              z-index: 100;
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }

            .selectContainer {
              flex-shrink: 0;
              display: flex;
              align-items: center;
              gap: 10px;
            }

            .select {
              width: 100%;
              border: #628395 1px solid;
              border-radius: 3px;
              background: #f9f9f9;
              color: rgb(99, 108, 107);
              font-size: 14px;
              font-weight: 400;
              font-family: inherit;
              letter-spacing: normal;
              line-height: 1.5;
              margin: 0;
              appearance: menulist;
              align-items: center;
              white-space: pre;
              -webkit-rtl-ordering: logical;
              cursor: default;
              text-indent: 0px;
              text-shadow: none;
              display: inline-block;
              text-align: start;
              word-spacing: normal;
              margin-right: 15px;
            }

            .selectTitle {
              margin: 0;
              padding: 0;
              font-size: 12px;
              flex-shrink: 0;
            }

            @media (min-width: 992px) {
              .Partnership-Row {
                width: 960px;
              }
            }
          `}</style>

          <style jsx="true">{``}</style>
        </div>
      </Layout>
    </ParallaxProvider>
  )
}
